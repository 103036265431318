import React from 'react';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import DetailOverlay, {
  IProps as IBaseProps,
} from '../../sharedComponents/DetailOverlay';
import Connections, {
  IConnection as IOverlayConnection,
} from '../../sharedComponents/DetailOverlayConnections';
import {
  getDisplayedProductCode,
} from '../../Utils';
import {
  IConnection,
} from '../../workerStore/network/Utils';

interface IProps extends IBaseProps {
  connections: IConnection[];
  productClass: ProductClass;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {connections, productClass, ...rest} = this.props;
    const overlayConnections: IOverlayConnection[] = connections.map(
      ({color, longLabel, id, code, level}) => ({
        color, id, label: longLabel, code:  getDisplayedProductCode(code, productClass, level),
      }),
    );

    return (
      <DetailOverlay {...rest}>
        <Connections
          productClass={productClass}
          connections={overlayConnections}
          title={__lexiconText('applicationWide.detailOverlayConnections.primary')}/>
      </DetailOverlay>
    );
  }
}
