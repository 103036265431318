import reverse from 'lodash-es/reverse';
import uniq from 'lodash-es/uniq';
import React from 'react';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import Tooltip from '../../sharedComponents/NetworkTooltip';
import {
  ITooltipNode,
} from '../../workerStore/network/Utils';
import {
  ITransformationMatrix,
} from '../panZoom';

interface IProps {
  tooltipMap: Record<string, ITooltipNode>;
  hoveredProduct: number | undefined;
  highlightedProduct: number | undefined;
  selectedProducts: number[];
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
  transformationMatrix: ITransformationMatrix;
  productClass: ProductClass;
  closeTooltip: (id: number) => void;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {
      tooltipMap, hoveredProduct, highlightedProduct, selectedProducts,
      chartContainerHeight, chartContainerWidth, transformationMatrix,
      productClass, closeTooltip,
    } = this.props;
    // Note: the tooltips have the following "z-index" order: hover tooltips
    // on top, followed by selection tooltips followed by highlight tooltip on
    // the bottom. We add them to the `tooltipIDs` array in the opposite order
    // (hover first, highlighted last) to accommodate the `uniq` function,
    // which retains the first occurrence:
    let tooltipIDs: number[] = [];
    if (hoveredProduct !== undefined) {
      tooltipIDs = [hoveredProduct];
    }
    tooltipIDs = [...tooltipIDs, ...selectedProducts];
    if (highlightedProduct !== undefined) {
      tooltipIDs = [...tooltipIDs, highlightedProduct];
    }
    const uniqueIDs = reverse(uniq(tooltipIDs));
    const tooltipElems = uniqueIDs.map(id => {
      const {scaledX, scaledY, shortLabel, code, level} = tooltipMap[id];
      return (
        <Tooltip key={id}
        svgWidth={chartContainerWidth}
        svgHeight={chartContainerHeight}
        transformationMatrix={transformationMatrix}
        productName={shortLabel}
        productCode={code}
        xOffset={scaledX}
        yOffset={scaledY}
        productLevel={level}
        closeTooltip={() => closeTooltip(id)}
        productClass={productClass}/>
      );
    });
    return (
      <>
        {tooltipElems}
      </>
    );
  }

}
