import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  fetchJSON,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export interface INode {
  x: number;
  y: number;
  id: number;
}

export interface IEdge {
  source: number;
  target: number;
  strength: number;
}
export interface ILayoutData {
  nodes: INode[];
  edges: IEdge[];
}

type IAPIResponse = ILayoutData;

export type IState = IBaseState<ILayoutData>;

const FETCH_BEGIN = 'NEW_PRODUCT_SPACE_LAYOUT_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_PRODUCT_SPACE_LAYOUT_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_PRODUCT_SPACE_LAYOUT_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_PRODUCT_SPACE_LAYOUT_FETCH_IF_NEEDED';

type IHash = {productClass: ProductClass};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  ILayoutData,
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.productSpaceLayout,
  getFetchPromise: ({productClass}: IHash) => fetchJSON<IAPIResponse>(
    require('./layoutData/layout_' + productClass + '.json'), true,
  ),
  hashFunction: ({productClass}: IHash) => productClass,
  getDataFromAPIResponse: root => root,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
